'use strict';

angular.module('model.AccountReadModel', [
    'ram',
    'config',
    'model.Account',
    'model.Custodian'
  ])
  .factory('AccountReadModel', [
    '$http',
    'ram',
    'config',
    'Account',
    'Custodian',
    accountReadModelFactory
  ]);

function accountReadModelFactory($http, ram, config, Account, Custodian) {

  /**
   * Constructor for PaginatedAccount model.
   * @class
   */
  var AccountReadModel = new ram.Collection('AccountReadModel', {
    accessors: ['skipRebalancing', 'accountApprovalRole', 'accountOpeningApiInfo', 'lastRejectedByRole'],
    schema: config.schemas.AccountReadModel,
    bind: ['account', 'approve', 'canRegenerateForms', 'regenerateForms', 'custodianId', 'applicationStatus'],
    enums: {
      type: config.types.Account,
      status: config.types.AccountStatus
    },
    resources: {
      default: new ram.resources.Http('/api/account_read_models/:id.json'),
      cookie: new ram.resources.Cookie('account_read_models')
    }
  });

  AccountReadModel.prototype.account = function() {
    return Account.find({
      id: this.accountId()
    });
  };

  AccountReadModel.prototype.approve = function() {
    var self = this;
    return this.account().then(function(account) {
      return account.approve();
    }).then(function() {
      return self.reload();
    });
  };

  AccountReadModel.prototype.canRegenerateForms = function() {
    return this.applicationCompleted() && !this.status.is.open();
  };

  AccountReadModel.prototype.regenerateForms = function(individualDocs=[]) {
    var self = this;
    return this.account().then(function(account) {
      return account.regenerateForms(individualDocs);
    }).then(function() {
      return self.reload();
    });
  };

  AccountReadModel.prototype.sendDocsReminder = function (agreementId, memberId, docNames) {
    return $http.post(`/api/account_read_models/${this.id}/agreement/reminder.json`, {
      agreement_id: agreementId,
      member_id: memberId,
      doc_names: docNames
    });
  };

  AccountReadModel.prototype.custodianCode = function() {
    return Custodian.codeFor(this.custodianId());
  };

  AccountReadModel.prototype.applicationStatus = function() {
    return $http.get('/api/account_read_models/' + this.id + '/application_status.json');
  };

  AccountReadModel.prototype.agreement = async function () {
    return $http.get(`/api/account_read_models/${this.id}/agreement.json`);
  };

  return AccountReadModel;
}
