'use strict';

/***********************************************************************************
 * OLOO base object - https://github.com/nboostani/modernadvisor-main/wiki/OLOO-Pattern---JavaScript-Objects-Linking-to-Other-Objects
 */
var dropdownDataHolder = {
  options: [],
  setOptions: null, // to be provided below...
  defaultOption: 0,
  filterOptions: parentFilterOptions,
  getSelected: parentGetSelected,
  setDefault: parentSetDefault,
  setModel: parentSetModel,
};

/**
 * OLOO derived objects, one for each dropdown
 */
var documentTypeDropdownData = Object.create(dropdownDataHolder);
documentTypeDropdownData.setOptions = setOptionsDocumentType;
documentTypeDropdownData.getSelected = getSelectedDocumentTypeId;

var yearDropdownData = Object.create(dropdownDataHolder);
yearDropdownData.setOptions = setOptionsYear;

var accountDropdownData = Object.create(dropdownDataHolder);
accountDropdownData.setOptions = setOptionsAccount;

/***********************************************************************************
 * General case controller for this component
 */
class FilteredClientDocumentsController {
  constructor($scope, ram, config, FilteredDocumentHolder, securityServiceFactory) {
    this.$scope = $scope;
    this.ram = ram;
    this.config = config;
    this.FilteredDocumentHolder = FilteredDocumentHolder;
    this.securityServiceFactory = securityServiceFactory;
  }

  $onInit() {
    const platform = this.config.env.platform;
    const isWsi = platform && platform.name === 'WSI';

    this.filterTool = new this.FilteredDocumentHolder(this.client.documents());

    const currentUserSecurity = this.securityServiceFactory.create(this.$scope.$root.currentUser);

    documentTypeDropdownData.filterOptions = filterOptionsDocumentType.bind(this, isWsi, currentUserSecurity);
    documentTypeDropdownData.setOptions(this.config.types.Document.filter((doc) => !doc.hidden));
    this.documentTypeSelected = new this.ram.Accessor(documentTypeDropdownData.defaultOption);
    this.documentTypes = documentTypeDropdownData.options;
    documentTypeDropdownData.setModel(this.documentTypeSelected);

    yearDropdownData.setOptions(this.filterTool);
    this.yearSelected = new this.ram.Accessor(yearDropdownData.defaultOption);
    this.years = yearDropdownData.options;
    yearDropdownData.setModel(this.yearSelected);

    accountDropdownData.setOptions(this.clientAccounts);
    this.accountSelected = new this.ram.Accessor(accountDropdownData.defaultOption);
    this.displayAccounts = accountDropdownData.options;
    accountDropdownData.setModel(this.accountSelected);

    this.filterAction();
  }

  getAccountNumberText(document) {
    const account = _(this.client.viewableAccounts()).findWhere({
      id: document.relatedToId()
    });
    const accountNumber = !account ? 'not available' : account.number() || 'not assigned';
    const shortLabel = !account ? 'not available' : account.shortLabel();
    
    return shortLabel + ' - ' + accountNumber;
  }

  getDescriptionText(document) {
    const documentTypesWithPersonNameInFilename = ['RESP Annex B', 'RESP Annex D'];

    if (
      documentTypesWithPersonNameInFilename.some((type) => document.type().label.includes(type)) &&
      document.signer() != null
    ) {
      if (
        document.type().label === 'RESP Annex D' &&
        this.client.fullName() !== document.signer()
      ) {
        return `${document.type().label} - ${this.client.fullName()} / ${document.signer()}`;
      }
      return `${document.type().label} - ${document.signer()}`;
    }
    return document.type().label;
  }

  loadModifyDocumentModal(document) {
    this.$scope.$root.advisorModifyDocumentModal(document, [...this.config.types.Document].sort((x, y) => x.label.localeCompare(y.label)), this.attachToOptions);
  }

  filterAction(hint = null) {
    if (hint) {
      this._processHint(hint);
    }
    const year = yearDropdownData.getSelected();
    const accountId = accountDropdownData.getSelected();
    const documentTypeId = documentTypeDropdownData.getSelected();

    this.filteredDocuments = this.filterTool
      .reset()
      .filterByYear(year)
      .filterByAccount(accountId)
      .filterByDocumentType(documentTypeId)
      .result();
  }

  _processHint(hint) {
    if (hint === 'account' && accountDropdownData.getSelected() === 'user') {
      this.documentTypeSelected(0);
      this.yearSelected(0);
    }
  }
}

export default FilteredClientDocumentsController;


/***********************************************************************************
 * Per-dropdown setup methods
 */

// Note use of .bind() to pre-fill the first 2 parameters
function filterOptionsDocumentType(isWsi, currentUserSecurity, item) {
  if (!isWsi && item.fccShow) {
    return false;
  } else if (item.superadvisorOnly) {
    return currentUserSecurity.permissions.viewSuperAdvisorDocuments();
  }
  return true;
}

function setOptionsDocumentType(input) {
  const _this = this; // jshint ignore:line

  const prependOptions = [
    {
      label: 'All',
      name: 'all'
    }
  ];

  const documentTypesWithDifferentVersions = [
    'RESP Annex A',
    'RESP Annex B',
    'RESP Annex D',
    'RESP Enform'
  ];

  const uniqueMap = {};

  _.forEach(input, function (item) {
    const group = documentTypesWithDifferentVersions.find((prefix) =>
      item.label.startsWith(prefix)
    );

    if (group) {
      uniqueMap[group] = {
        label: group,
        name: group,
        typeId: group
      };
    } else {
      uniqueMap[item.label] = {
        label: item.label,
        name: item.name,
        typeId: item.id
      };
    }
  });

  const uniqueOptions = _.values(uniqueMap);

  const options = prependOptions.concat(
    _.map(
      uniqueOptions.sort((x, y) => x.label.localeCompare(y.label)),
      function (item) {
        return {
          label: item.label,
          name: item.name,
          typeId: item.typeId
        };
      }
    )
  );

  _this.options = mapSetValueIndex(options);
  _this.setDefault('all');
}

function getSelectedDocumentTypeId(index) {
  var parent = this; // jshint ignore:line
  index = index || (parent.model && parent.model());
  return parent.options[index].typeId;
}

function setOptionsYear(filterTool) {
  var parent = this; // jshint ignore:line

  var prependOptions = [{
    label: 'All',
    name: 'all'
  }, {
    label: 'YTD',
    name: 'ytd'
  }];

  var years = filterTool.getYears();
  var options = prependOptions.concat(_.map(years, function(item) {
    return {
      label: item,
      name: parseInt(item)
    };
  }));

  parent.options = mapSetValueIndex(options);
  parent.setDefault(years[0] || 'all');
}

function setOptionsAccount(input) {
  var parent = this; // jshint ignore:line

  var prependOptions = [{
    label: 'All',
    name: 'all'
  }, {
    label: 'User Documents',
    name: 'user'
  }];

  var options = prependOptions.concat(_.map(input, function(item) {
    return {
      label: item.label(),
      name: item.id,
    };
  }));

  parent.options = mapSetValueIndex(options);
  parent.setDefault('all');
}

function mapSetValueIndex(options) {
  return _.map(options, function(item, index) {
    return Object.assign(item, {
      value: index
    });
  });
}

/***********************************************************************************
 * Parent object helper methods
 */
function parentFilterOptions() {
  return true;
}

function parentGetSelected(index) {
  var self = this; // jshint ignore:line
  index = index || (self.model && self.model());
  return self.options[index].name;
}

function parentSetDefault(name) {
  var self = this; // jshint ignore:line
  self.defaultOption = _.find(self.options, function(item) {
    return item.name === name;
  }).value;
}

function parentSetModel(model) {
  var self = this; // jshint ignore:line
  self.model = model;
}
